<template>
  <div class="add">
    <div class="add_btn">
      <el-button
        type="primary"
        @click="onAdd">
        新增服务
      </el-button>
    </div>
    <div class="add_list tw-flex tw-flex-wrap">
      <div
        class="add_list_item item"
        v-for="(item,index) in value"
        :key="index"
        @click="onEdit(item, index)">
        <div class="item_header tw-flex tw-items-center tw-justify-between">
          <p class="text_size_18 tw-font-bold">
            {{index + 1}}.{{getServiceType(item.serviceTypeId).parent}}--{{getServiceType(item.serviceTypeId).child}}
          </p>
          <i
            class="item_header_icon iconfont iconchacha"
            @click.stop="onDelete(index)" />
        </div>
        <div class="item_body tw-flex tw-items-center">
          <p class="text_size_14 item_body_subtype">
            {{getServiceType(item.serviceTypeId).grandChild}}
          </p>
        </div>
      </div>
    </div>

    <el-dialog
      :title="editIndex === -1 ? '新增服务' : '修改服务'"
      :visible.sync="dialogVisible"
      width="600px"
      :show-close="false"
      @close="onClose">
      <div class="checkbox">
        <el-checkbox-group
          v-model="check"
          :max="1">
          <el-collapse accordion>
            <el-collapse-item
              class="checkbox-collapse-item"
              v-for="item in serviceTypeList"
              :key="item.id"
              :title="item.name"
              :name="item.id">
              <el-collapse accordion>
                <el-collapse-item
                  class="checkbox-collapse-sub-item"
                  v-for="subItem in item.children"
                  :key="subItem.id"
                  :title="subItem.name"
                  :name="subItem.id">
                  <div
                    class="checkbox_item"
                    v-for="service in subItem.children"
                    :key="service.id">
                    <p class="checkbox_item_label text_size_14">
                      {{service.name}}
                    </p>
                    <el-checkbox :label="service.id">
                      <span></span>
                    </el-checkbox>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </el-checkbox-group>
      </div>
      <div slot="footer">
        <el-button
          size="small"
          @click="onCancel">
          取消
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="onConfirm">
          确认选择
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      dialogVisible: false,
      editIndex: -1,
      check: [],
      serviceTypeList: []
    }
  },
  computed: {
    getServiceType () {
      return (id) => {
        let parent = ''
        let child = ''
        let grandChild = ''

        this.serviceTypeList.forEach(parentItem => {
          parentItem.children.forEach(childItem => {
            childItem.children.forEach(grandChildItem => {
              if (grandChildItem.id === id) {
                parent = parentItem.name
                child = childItem.name
                grandChild = grandChildItem.name
              }
            })
          })
        })

        return { parent, child, grandChild }
      }
    }
  },
  mounted () {
    this.getServiceTypeList()
  },
  methods: {
    onAdd () {
      this.dialogVisible = true
    },
    getServiceTypeList () {
      if (this.serviceTypeList.length > 0) return
      api.getServiceTypeCheckList().then(res => {
        if (res.data.code === 0) {
          this.serviceTypeList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onClose () {
      this.check = []
    },
    onCancel () {
      this.dialogVisible = false
    },
    onConfirm () {
      if (this.check.length < 1) return

      if (this.editIndex === -1) {
        this.$emit('input', [
          ...this.value,
          {
            serviceTypeId: this.check[0],
            serviceId: ''
          }
        ])
      } else {
        const array = this.$clone(this.value)
        array.splice(this.editIndex, 1, {
          serviceTypeId: this.check[0],
          serviceId: ''
        })

        this.$emit('input', [...array])
      }
      this.editIndex = -1
      this.dialogVisible = false
    },
    onEdit (item, index) {
      this.editIndex = index
      this.check = [item.serviceTypeId]
      this.dialogVisible = true
    },
    onDelete (index) {
      const array = this.$clone(this.value)
      array.splice(index, 1)
      this.$emit('input', [...array])
    }
  }
}
</script>

<style lang="scss" scoped>
.add {
  &_btn {
    margin-bottom: 20px;
  }
  &_list {
    margin: -10px;
    &_item {
      background: #FFFFFF;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      &:hover {
        cursor: pointer;
        border: 1px solid #3473E6;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .item {
      width: 320px;
      height: 90px;
      margin: 10px;
      &_header {
        height: 40px;
        padding: 0 17px 0 10px;
        background-image: url('../../../assets/images/service_org_bg.png');
        background-size: 270px 40px;
        background-repeat: no-repeat;
        background-position: top right;
        &_icon {
          color: #F36A6E;
          &:hover {
            color: rgba($color: #F36A6E, $alpha: 0.8);
          }
        }
      }
      &_body {
        height: 50px;
        padding-left: 20px;
        &_subtype {
          line-height: 20px;
          color: #C0C4CC;
          & > span {
            margin-left: 10px;
            color: #606266;
          }
        }
      }
    }
  }
}

.checkbox {
  height: 350px;
  padding: 10px;
  overflow: auto;
  border: 1px solid #DCDFE6;
  border-radius: 2px;
  &_item {
    height: 50px;
    padding: 0 25px 0 45px;
    border-top: 1px solid #DCDFE6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}
</style>

<style lang="scss">
.checkbox-collapse-item {
  .el-collapse-item__header {
    font-size: 14px;
    padding: 0 25px 0 10px;
  }
  .el-collapse-item__wrap .el-collapse-item__content {
    padding-bottom: 0;
  }
}
.checkbox-collapse-sub-item {
  .el-collapse-item__header {
    padding-left: 25px;
  }
}
</style>
