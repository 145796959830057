// 服务链详情
<template>
  <div class="detail">
    <detail-header :title="title" />
    <div class="detail_main page_width">
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="140px"
        label-position="left">
        <el-form-item
          label="服务链名称"
          prop="name">
          <el-input
            maxlength="50" show-word-limit
            v-model="formData.name"
            placeholder="请输入服务链名称"
            :style="{width: '380px'}">
          </el-input>
        </el-form-item>
        <el-form-item
          label="所属行业"
          prop="industryId">
          <el-select
            v-model="formData.industryId"
            :style="{width: '380px'}"
            placeholder="请选择行业领域">
            <el-option
              v-for="item in industrySectorList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="服务链说明"
          prop="description">
          <el-input
            v-model="formData.description"
            type="textarea" maxlength="300" show-word-limit
            placeholder="请输入服务链说明"
            :autosize="{minRows: 4, maxRows: 4}">
          </el-input>
        </el-form-item>
        <el-form-item
          label="服务链环节"
          prop="steps">
          <add-service v-model="formData.steps" />
        </el-form-item>
        <el-form-item size="large">
          <template v-if="id">
            <el-button
              v-if="enabled"
              type="danger"
              size="small"
              @click="toggleStatus(0)">
              禁用
            </el-button>
            <el-button
              v-else
              type="success"
              size="small"
              @click="toggleStatus(1)">
              启用
            </el-button>
          </template>
          <el-button
            type="primary"
            size="small"
            :loading="btnLoading"
            @click="submitForm">
            保存服务链
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

import addService from './add-service'

export default {
  components: {
    addService
  },
  data () {
    return {
      formData: {
        name: '',
        industryId: '',
        description: '',
        steps: [
          // { serviceTypeId: '', serviceId: '' }
        ]
      },
      rules: {
        name: [
          { required: true, message: '请输入服务链名称', trigger: 'blur' }
        ],
        industryId: [
          { required: true, message: '请选择行业领域', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入服务链说明', trigger: 'blur' }
        ],
        steps: [
          { required: true, message: '请设置服务链', trigger: 'blur' }
        ]
      },
      enabled: 0,
      btnLoading: false
    }
  },
  computed: {
    type () {
      return this.$route.params.type
    },
    id () {
      return this.$route.params.id || ''
    },
    title () {
      return {
        create: '服务链定制',
        edit: '服务链详情'
      }[this.type]
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    }
  },
  mounted () {
    if (this.type === 'edit') {
      this.getFormData(this.id)
    }
  },
  methods: {
    getFormData (id) {
      api.getServiceChainDetail(id).then(res => {
        if (res.data.code === 0) {
          for (const key in this.formData) {
            if (Object.hasOwnProperty.call(this.formData, key)) {
              if (key === 'steps') {
                this.formData[key] = []
                res.data.data[key].forEach(element => {
                  this.formData[key].push({
                    serviceTypeId: element.serviceTypeId,
                    serviceId: element.serviceId
                  })
                })
              } else {
                this.formData[key] = res.data.data[key]
              }
            }
          }
          this.enabled = res.data.data.enabled
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    submitForm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.type === 'create') {
            this.btnLoading = true
            api.createServiceChain({
              ...this.formData
            }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('服务链已添加')
                this.$router.push('/service-chain-manage')
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
              this.$message.error('请求出错')
            }).finally(() => {
              this.btnLoading = false
            })
          } else if (this.type === 'edit') {
            this.btnLoading = true
            api.editServiceChain(this.id, {
              ...this.formData
            }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('服务链已保存')
                this.getFormData(this.id)
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
              this.$message.error('请求出错')
            }).finally(() => {
              this.btnLoading = false
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toggleStatus (enabled) {
      api.toggleServiceChainStatus(this.id, {
        enabled
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('状态已更改')
          this.getFormData(this.id)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 40px 0;
    &_service {
      margin-top: 40px;
    }
  }
}
</style>
